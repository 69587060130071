import React from "react"
import { PageProps } from "gatsby"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { Meta, ContactBox, ShareButtons } from "../components"
import { SEO } from "../components/Seo/Seo";


const Contact: React.FC<PageProps> = () => ( 
  <PageLayout>
    <Container>
      <Meta title="Contact" />
      <Section>
        <ContactBox/>
      </Section>
      <Section>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </Section>        
    </Container>
    <ShareButtons/>
  </PageLayout>
)

const Container = styled("section", {
  maxWidth: 400,
  length: 0
})

const Section = styled("section", {
  marginTop: "$0",
  length: 0
})

export default Contact;

export const Head = () => (
  <SEO title="contact" pathname="/contact"/>
)
